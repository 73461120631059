import clsx from "clsx";
import { useAtom } from "jotai";

import imageIcon from "../../../img/icons/image.svg";

import type { SupplierProductGroup } from "../../generated";
import { selectedProductGroupState } from "../../jotai/products";
import { Typography } from "../../library";
import ImageWithFallback from "../../library/ImageWithFallback";
import { borderColorClass } from "../../utils/colors";

interface ProductGroupProps {
  productGroup: SupplierProductGroup;
}

export default function ProductGroup({ productGroup }: ProductGroupProps) {
  const [selectedProductGroup, setSelectedProductGroup] = useAtom(
    selectedProductGroupState
  );
  const isSelected = productGroup === selectedProductGroup;

  return (
    <div
      className={clsx(
        "flex-1 rounded-2xl border border-solid divide-y inline-flex flex-col justify-start items-start overflow-hidden cursor-pointer",
        isSelected
          ? borderColorClass.brand.bold.enabled
          : borderColorClass.neutral.subtle.enabled,
        {
          shadow: isSelected,
        }
      )}
      onClick={() => setSelectedProductGroup(productGroup)}
    >
      <div className="self-stretch divide-x h-28 max-h-28 inline-flex justify-start items-start overflow-hidden">
        {[0, 1].map((index) => (
          <ImageWithFallback
            key={index}
            className="h-28 w-1/2"
            src={productGroup.products[index]?.variants[0]?.photos[0]?.url}
            alt={`${productGroup.name}-image-${index + 1}`}
            border={false}
            fit="object-contain"
            srcFallback={imageIcon}
          />
        ))}
      </div>
      <div className="self-stretch p-4 inline-flex justify-start items-start gap-4">
        <div className="flex-1 inline-flex flex-col justify-start items-start gap-2">
          <Typography
            className="self-stretch h-11 justify-start font-semibold"
            color="neutral.boldest.enabled"
          >
            {productGroup.name}
          </Typography>
        </div>
      </div>
    </div>
  );
}
