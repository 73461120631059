import { useAtomValue } from "jotai";
import imageIcon from "../../../img/icons/image.svg";
import { styleSearchResponseText } from "../../components/ContractSearch/utils";
import useShowModal from "../../hooks/useShowModal";
import { productState, selectedProductGroupState } from "../../jotai/products";
import { Table, Typography } from "../../library";
import ImageWithFallback from "../../library/ImageWithFallback";
import { modals } from "../../utils/enums";
export default function ProductTable({
  trackOpenProductDetail,
}: {
  trackOpenProductDetail: (id: string) => void;
}) {
  const { isLoading, products } = useAtomValue(productState);
  const selectedProductGroup = useAtomValue(selectedProductGroupState);
  const showProductDetailModal = useShowModal(modals.PRODUCT_DETAIL_MODAL);

  return (
    <Table
      hideHeader // If more columns are added, show a header
      columns={[
        {
          key: "itemName",
          label: "Product",
          isSortable: false,
          render: (v, { id, variants, highlightResult }) => {
            const firstVariantUrl = variants?.[0]?.photos[0]?.url;
            const onClick = () => {
              trackOpenProductDetail(id);
              showProductDetailModal({ itemName: v, variants });
            };
            const itemName = v;
            let itemText = itemName;
            if (highlightResult?.itemName?.length) {
              itemText = highlightResult.itemName[0].value;
            }
            return (
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={onClick}
              >
                {firstVariantUrl && (
                  <ImageWithFallback
                    className="shrink-0 w-10 h-10"
                    src={firstVariantUrl}
                    alt={`${v}-image`}
                    fit="object-contain"
                    srcFallback={imageIcon}
                    fallbackClassName="p-1.5"
                  />
                )}
                <div>
                  <Typography
                    size="sm"
                    title={itemName}
                    color="neutral.boldest.enabled"
                  >
                    {styleSearchResponseText(itemText)}
                  </Typography>
                  <Typography
                    size="sm"
                    color="neutral.bold.enabled"
                    className="empty:hidden line-clamp-1"
                  >
                    {variants?.at(0)?.notes}
                  </Typography>
                </div>
              </div>
            );
          },
        },
      ]}
      data={selectedProductGroup?.products || products.hits}
      getId={(v) => v.id}
      isLoading={isLoading}
      loadingRowCount={6}
      loadingRowClassName="h-10"
    />
  );
}
